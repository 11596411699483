import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RrhhService } from 'src/app/modules/rrhh/services/rrhh.service';

@Component({
  selector: 'app-validar-constancias',
  templateUrl: './validar-constancias.component.html',
  styleUrls: ['./validar-constancias.component.css']
})
export class ValidarConstanciasComponent implements OnInit {
id:any
idPlanilla:any
  constructor(private srvPlanilla:RrhhService,private ActivatedRoute: ActivatedRoute) {

  }
  url:any
  safe:any
  loading:boolean=true
  ngOnInit(): void {
    this.id=(this.ActivatedRoute.snapshot.paramMap.get('id'))
    this.idPlanilla=(this.ActivatedRoute.snapshot.paramMap.get('idPlanilla'))
  this.loadConstancia(this.id)
  }

  loadConstancia(id){
    console.log(id)

    var obj={
        bucket:'ayudaenaccionrepo.go504hn.com',
        key:`${this.idPlanilla}/${id}.pdf`,
        time:'60',
        tipo:'url'
    }
      this.srvPlanilla.validarConstanciaPost(obj,data=>{
       this.url=data.data[0].table0[0].url;
       console.log(this.url);
       this.loading=false
      })
  }



}

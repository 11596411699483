import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  public token: string;
  constructor(private router: Router, public auth:AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

     this.token = localStorage.getItem('token_go504');
    // this.token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IklkVXN1YXJpbyI6IjYzODZFRDU3LUMyOTEtNDE0OS04RDFGLTgzOTcxRkNEQTYyMSIsIlVzdWFyaW8iOiJjb21wcmFzIiwiQ29kRW1wbGVhZG8iOiIxMjMiLCJOb21icmUiOiJBRE1JTiIsIk9ic2VydmFjaW9uZXMiOiIiLCJjYW1iaW9Db250cmFzZW5hIjp0cnVlLCJjYW1iaW9QaW5TZWd1cmlkYWQiOnRydWUsImNvcnJlb0VsZWN0cm9uaWNvIjoibWFsdmFyYWRvbGFpbmV6QGdtYWlsLmNvbSIsIm1mYUVuYWJsZSI6ZmFsc2UsInBpbiI6IjI5MDciLCJpZFRpcG9Vc3VhcmlvIjoxLCJpZFBlcnNvbmEiOiIyNiIsImlkQWZpbGlhZG8iOm51bGwsImlkRXN0dWRpYW50ZSI6IjEzIiwiaWRFbXBsZWFkbyI6IjAiLCJjb2RpZ29FbXBsZWFkbyI6IjAiLCJjdWVudGFFc3R1ZGlhbnRpbCI6IjYzMDA0NCIsImVudiI6ImFhIiwiYnVja2V0IjpudWxsLCJrZXlJbWciOm51bGwsImlkVXN1YXJpb0ludCI6IjEifSwiaWF0IjoxNzI4NDQyMTk2LCJleHAiOjE3Mjg0NDU3OTZ9.iqp41HC70qhDl4UohnbR-dPOssh59-LRQyr2TI2lJFg'

    if (this.token == null) {
      this.token = '';
    }

    let request = req;
    // request = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin','*')})
    // request = req.clone({ headers: req.headers.set('Access-Control-Request-Headers','*')})
    // request = req.clone({ headers: req.headers.set('Content-Type','application/json')})
    // request = req.clone({ headers: req.headers.set('Content-Encoding','gzip')})


 
    if (this.token) {
      request = req.clone({
        setHeaders: {
          'x-api-key': 'SLLezg7leN10hFGnCZAgB1r0zt8CYTxV87JsYQRu',
          authorization: 'Bearer ' + this.token,

          
         
        }
        
      });


    }
 

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.auth.CerrarSesion();
        }

        return throwError(err);

      })
    );
  }
}
